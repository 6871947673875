<script>
export default {
  emits: ["remove"],
  props: {
    commentFiles: {
      type: Array,
      default: [],
    },
    showImages: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    deleteUpload(index) {
      this.$emit("remove", index);
    },
  },
};
</script>
<template>
  <div class="row mt-3" v-if="commentFiles && commentFiles.length > 0">
    <div
      class="col-lg-6 col-md-4"
      v-for="(file, index) in commentFiles"
      :key="index"
    >
      <div class="attachment-card">
        <div class="d-flex align-items-center">
          <div class="">
            <span v-if="file.type === 'png'">
              <img alt="png" />
            </span>
            <span v-else-if="file.type === 'pdf'">
              <img alt="pdf" />
            </span>
            <span v-else-if="file.type === 'csv'">
              <img alt="csv" />
            </span>
            <span v-else-if="file.type === 'jpg'">
              <img alt="jpg" />
            </span>
            <span v-else-if="file.type === 'jpeg'">
              <img alt="jpeg" />
            </span>
            <span v-else-if="file.type === 'doc'">
              <img alt="doc" />
            </span>
            <span v-else-if="file.type === 'docx'">
              <img alt="docx" />
            </span>
            <span v-else-if="file.type === 'ppt'">
              <img alt="ppt" />
            </span>
            <span v-else-if="file.type === 'pptx'">
              <img alt="pptx" />
            </span>
            <span v-else-if="file.type === 'xls'">
              <img alt="xls" />
            </span>
            <span v-else-if="file.type === 'xlsx'">
              <img alt="xlsx" />
            </span>
          </div>
          <div class="col ps-0">
            <a href="javascript:void(0);">{{ file.name || "no file name" }}</a>
            <p class="mb-0">{{ file.size || "MB" }}</p>
          </div>
        </div>
        <a @click="deleteUpload(index)" class="text-danger">
          <feather-icon size="16" icon="TrashIcon" />
        </a>
      </div>
    </div>
  </div>
</template>
